import 'owl.carousel';
import 'jquery-validation';
import './jquery.selectric.min.js';
import IMask from 'imask';


$(document).ready(function () {
    $('select:not('.styled')').selectric({
        maxHeight: 200,
        nativeOnMobile: true,
        onChange: function (element) {
            const $parent = $(element).parents('.form-label-group');
            if ($(element).val() !== '') {
                $parent.find('.selectric-label-below').removeClass('hidden');
            } else {
                if (!$parent.find('.selectric-label-below').hasClass('hidden')) $parent.find('.selectric-label-below').addClass('hidden');
            }
        }
    });

    $('textarea').on('input', function () {
        this.style.height = '1px';
        this.style.height = this.scrollHeight + 'px';
    });

    $(".magnitForm").each(function () {

        $(this).validate({
            rules: {
                email: {
                    required: true,
                    email: true
                },
                type_of_work: {
                    required: true
                }
            },
            messages: {
                theme: "Вы не указали предмет",
                type_of_work: "Укажите тип работы",
                name: "Вы не указали имя",
                email: "Вы не указали E-mail",
                phone: "Вы не указали номер телефона"
            },
            errorElement: "span",

            submitHandler: function (form) {
                var email = $(form).find('[name=email]').val();
                $.post('/ajax/createOrder/', $(form).serialize(), function (res) {
                    console.log(email);
                    if (!res.status && !res.data && res.error) {
                        alert('Ошибка при создании заказа!');
                        console.log(res);
                        return false;
                    }
                    if (res.status && res.data.order_id && res.data.authtoken && res.data.hash) {
                        return window.location.href = 'https://lendiplom-lk.ru/' + 'auth/token=' + res.data.authtoken + '/newOrderId=' + res.data.order_id + '/email=' + email + '/hash=' + res.data.hash + '/';
                    }
                    if (res.status && res.data && res.data.order_id && res.data.action == 'userIsset') {
                        return window.location.href = 'https://lendiplom-lk.ru/' + 'orders/newOrder/id=' + res.data.order_id + '/new/';
                    }
                }, 'json').fail(function (e) {
                    console.log(e);
                    alert('Произошла ошибка');
                    // window.location.reload();
                });
                return false;
            }
        });
    });

    var element = document.getElementById('phonefield');
    var maskOptions = {
        mask: '+{7} (000) 000-00-00',
        prepare: (appended, masked) => {
            if ((appended === '8' || appended === '7' || appended === '+' || appended === '+7') && masked.value === '') {
                return '';
            }
            return appended;
        }
    };

    if (!!element) IMask(element, maskOptions);

    var $window = $(window);

    $('.lendiplom-closeform-wrapper__form__toggle').on('click', function () {
        $(this).toggleClass('active');
        $('.lendiplom-closeform-wrapper__form__togglebox').toggleClass('open');
    });

    // if($window.width() > 480) {
    //     $('.lendiplom-closeform-wrapper__order__price').height($('.lendiplom-closeform-wrapper__order__summary').outerHeight());
    // }


    $('.lendiplom-magnit-popup__close').on('click', function () {
        $('#popup1, #popup2').removeClass('open');
    });
});